$(document).ready(function(){

/*    var $herzstolpernContentWrapper = $('.herzstolpern-content-wrapper');*/
    var $siteWrapper  = $('.site-wrapper ');
    var $sidebarMenu = $('[data-id="herzstolpern-sidebar"]');
    var $burgerMenuIcon = $('[data-id="herzstolpern-burger-menu"]');
    var $sidebarCloseBtn = $('[data-click="close-sidebar"]');
    var containerLimitClass = 'herzstolpern-container-limiter';
    var $herzstolpernCarousel = $('.carousel');
    var $herstolpernBackgroundImage = $('#herzstolpern-background-image');
    var $herzstolpernCarouselImage = $('.herzstolpern-carousel-image');

    var VIEWPORT_MOBILE_MAX = 960;

    function backgroundSetting(){
        //Check if the element exists on the screen
        if ($herstolpernBackgroundImage) {
            var imageUrl = $herstolpernBackgroundImage.find('img').attr('src'); //Fetch src from the image element

            if ($(window).width() > VIEWPORT_MOBILE_MAX) {
                //Using background image only in tablet and desktop viewport
                $herstolpernBackgroundImage.css('background-image', 'url(' + imageUrl + ')'); //Set the background-image src
                $herstolpernBackgroundImage.css('background-size', 'cover');
                $herstolpernBackgroundImage.css('background-position', '50% 50%');
                $herstolpernBackgroundImage.find('img').css('display', 'none'); // hiding the image element
            }

            else {
                $herstolpernBackgroundImage.css('background-image', 'none'); //Remove the background image
                $herstolpernBackgroundImage.find('img').css('display', 'block');// show the image element
            }
        }
    }

    backgroundSetting();


    function backgroundCarouselImage(){
        if ($herzstolpernCarouselImage) {
            $.each($herzstolpernCarouselImage, function(index, value){
                var $currentElement = $($herzstolpernCarouselImage[index]);
                var imageUrl = $currentElement.find('img').attr('src'); //Fetch src from the image element
                $currentElement.css('background-image', 'url(' + imageUrl + ')'); //Set the background-image src
                $currentElement.css('background-size', 'cover');
                $currentElement.css('background-position', '50% 50%');
                $currentElement.find('img').hide(); // hiding the image element
            });

        }
    }

    backgroundCarouselImage();


    $burgerMenuIcon.click(function(){
        $siteWrapper.addClass(containerLimitClass);
        $sidebarMenu.show();
    });

    $sidebarCloseBtn.click(function(){
        $siteWrapper.removeClass(containerLimitClass);
        $sidebarMenu.hide();
    });


    //No auto cycle
    $herzstolpernCarousel.carousel(
        {
            interval : false
        }
    );


    function carouselNormalizeHeight(){
        var items = $('#herzstolpern-carousel-inner-container .carousel-item'),              // grab all the slides
            heights = [],                                   // array to store heights
            mobile = false,
            tallestCarousel; // tallest slide

        function fetchAppropriateCarouselItems(){
            if ($(window).width() < VIEWPORT_MOBILE_MAX) {
                //Using different carousel for mobile so have to select mobile carousel items
                items = $('#herzstolpern-carousel-mobile .carousel-item');
                mobile = true;
            }
            else {
                items = $('#herzstolpern-carousel-inner-container .carousel-item');
                mobile = false;
            }
        }

        fetchAppropriateCarouselItems();

        if (items.length) {
            function normalizeHeights(){
                fetchAppropriateCarouselItems();
                items.each(function(){
                    heights.push($(this).height());
                });
                tallestCarousel = Math.max.apply(null, heights);        // find the largest height

                items.each(function(){
                    $(this).css('min-height', tallestCarousel + 'px');    // set each slide's minimum height
                    if (!mobile) {
                        $(this).find('.row').css('min-height', tallestCarousel + 'px');
                        $(this).find('.row').find('.vertical-align > a').css('min-height', tallestCarousel + 'px');
                    }
                    if (mobile) {
                        $(this).css('min-height', tallestCarousel + 'px');    // set each slide's minimum height
                        $(this).find('> a').css('min-height', tallestCarousel + 'px');    // set each slide's minimum height
                    }
                });
            }

            normalizeHeights();

            $(window).on('resize orientationchange', function(){
                tallestCarousel = 0, heights.length = 0;               // reset the variables

                items.each(function(){
                    var $rowElement = $(this).find('.row');
                    var $anchorElement = $(this).find('.row').find('.vertical-align > a');
                    // reset each slide's height
                    $(this).css('min-height', '0');
                    if ($rowElement !== undefined && $rowElement.css('min-height') !== 'auto') {
                        $rowElement.css('min-height', 'auto');
                        $anchorElement.css('min-height', 'auto');
                    }
                });

                normalizeHeights(); // run it again
                backgroundSetting(); //Run check for background-image setting
            });
        }
    }

    // carouselNormalizeHeight();

    $(window).on('resize orientationchange', function(){
        backgroundSetting(); //Run check for background-image setting
    });

    $('.herzstolpern-carousel-container').slick(
        {
            infinite : false,
            dots: true,
            slidesToShow : 3,
            slidesToScroll : 1,
            prevArrow:"<img class='a-left control-c prev slick-prev' src='/servlet/servlet.FileDownload?file=00P1o00001C1DBlEAN'>",
            nextArrow:"<img class='a-right control-c next slick-next' src='/servlet/servlet.FileDownload?file=00P1o00001C1DBbEAN'>",
            responsive: [
                {
                    breakpoint: 1920,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        }
    );


});


<!--video-->
$(document).ready(function(e) {


		player=null;
		
    $('.commonOverlayClass').click(function(e) {
		player=null;
		videojs=null;
		var count=0; 
		 $('.commonOverlayClass').show();
		var videoIdVal=$(this).attr('rel');
		$('.videoObjCode').empty();
                 $('.videoContainer').removeClass('active');
                 $(this).parent().addClass("active");
				 $(this).hide();
                  
		var ua = window.navigator.userAgent;//checking for IE as autoplay attribute is only required for IE...
		var msie = ua.indexOf("MSIE ");
		$('.active .videoObjCode').html('<video id="video" style="width: 100%; height: 100%;" data-video-id="'+videoIdVal+'" data-account="4132705252001" data-player="Sye4hzHWb" data-embed="default" data-application-id class="video-js" controls autoplay></\video><script src="//players.brightcove.net/4132705252001/Sye4hzHWb_default/index.min.js"></\script>');
		
		var content=setInterval(function(){
				if(player == null && ($(".active .videoObjCode").html())!=" " && ($(".active .videoObjCode").html())!="")
				{
					    videojs("video").ready(function(){
						player = this;
						
						player.on('play',function(){
	                    
	});

					});
					clearInterval(content);
				}
				

			},2000);

	

});



});